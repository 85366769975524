<template>
  <nav class='toad-menu-chip' @click='$emit("click")'>
    <div class='toad-menu-chip__icon'>
      <img src="./../../assets/icons/menu-lines.svg" />
    </div>
    <lp-avatar 
      :pictureUrl='pictureUrl' 
      :notifications='notifications' 
    />
  </nav>
</template>
<script>
import LpAvatar from './LpAvatar.vue';
export default {
  components: {
    LpAvatar
  },
  props: {
    pictureUrl: {
      type: String,
      required: false,
      default: null
    },
    notifications: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
<style lang='scss' scoped>
.toad-menu-chip{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #334150;
  height: 64px;
  border-radius: 33px;
  padding: 0 4px;
  max-width: 124px;
  &__icon{
    margin: 0 19px;
    height: 19px;
    img{
      width: 22px;
      height:auto;
    }
  }
}
</style>