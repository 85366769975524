<template>
  <f7-page id='toad-login'>
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-list class='toad-form'>
            <lp-input 
              :inputValue='email'
              @input='(inputValue) => handleInputChange("email", inputValue)'
              inputPlaceholder='Email'
              inputType='email'
              helperText='Login with your Email' />
            <lp-input 
              :inputValue='password'
              @input='(inputValue) => handleInputChange("password", inputValue)'
              inputPlaceholder='Password'
              inputType='password'
              helperText='Enter your password' />
          </f7-list>
        </f7-col>
      </f7-row>
      <f7-row>
        <lp-button 
          @click='handleLogin' 
          text='Login'
          buttonType='primary'
        />
        <lp-button 
          @click='$router.push("/forgot-password")' 
          text='Forgot Password'
          buttonType='secondary'
        />
        <p>or <span @click='$router.push("/signup")'>signup</span></p>
      </f7-row>  
    </f7-block>
  </f7-page>
</template>
<script>
import firebase from "firebase";
import LpButton from './../components/lilypad/LpButton.vue';
import LpInput from './../components/lilypad/LpInput.vue';
export default {
  components: {
    LpInput, LpButton
  },
  data () {
    return {
      email: null,
      password: null
    }
  },
  mounted () {
    screen.orientation.lock('portrait');
  },
  methods: {
    handleInputChange(inputType, inputValue) {
      this[inputType] = inputValue;
    },
    handleLogin() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          console.log('successful login')
          this.$router.push({name: 'home'});
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }
}
</script>
<style lang='scss'>
  .toad-form{
    background-color: white;
  }
</style>