<template>
<div
  class="double-sided-page"
  :class="[{turned: turned}, `pg-${index}`]"
  :style="`z-index:${zIndex};`">
  <div
    class="front"
    @click='$emit("pageClick", page.front.pageNumber+1)'>

    <div class="turn-shadow"/>

    <div
      class="illustration"
      :style="`background-image:url(${getImageUrl(page.front)});`">
    </div>
    <div v-if='page.front.text' class="text">
      this is page {{page.front.pageNumber}}.
      <p>{{page.front.text}}</p>
    </div>
  </div>
  <div class="back"
    @click='$emit("pageClick", page.back.pageNumber-1)'>
    <div class="turn-shadow"/>
    <div
      class="illustration"
      :style="`background-image:url(${getImageUrl(page.back)});`">
    </div>

    <div v-if='page.back.text' class="text">
      this is page {{page.back.pageNumber}}.
      <p>{{page.back.text}}</p>
    </div>
  </div>
</div>
</template>
<script>
import {mapState} from 'vuex';
import { BOOK_BUCKET_URL } from './../api/endpoints';
export default {
  props: [
    'page',
    'index',
    'total',
    'currentPage',
  ],
  data () {
    return {
      turned: false,
      zIndex: 0,
      BOOK_BUCKET_URL
    }
  },
  watch: {
    currentPage: function (value) {
      if (value) {
        this.goToCurrentPage()
      }
    }
  },
  computed: {
    ...mapState('activity', ['profileBook'])
  },
  mounted () {
    this.zIndex = this.total - this.index;
    this.turned = false;
    setTimeout(() => {
      if (this.currentPage) {
        this.goToCurrentPage()
      }
    }, 200)
  },
  methods: {
    getImageUrl (page) {
      let pageImageUrl = '';

      if (page.colophon) {
        pageImageUrl = page.img
      } else {
        pageImageUrl = BOOK_BUCKET_URL(this.$route.params.bookSlug, page.img);
      }

      // if (asset) {
      //   pageImageUrl = BOOK_BUCKET_URL(this.$route.params.bookSlug, asset);
      // } else {
      //   if ((this.currentPage === 3 || this.currentPage === 4) && (this.profileBook.signing.signingImage)) {
      //     pageImageUrl = this.profileBook.signing.signingImage;  
      //   } else {
      //     pageImageUrl = BOOK_BUCKET_URL('global-assets', 'blank.png');  
      //   }    
      // }

      return pageImageUrl;
    },
    goToCurrentPage () {
      if (this.currentPage) {
        if (this.page.back.pageNumber <= this.currentPage){
          this.handleTurn(true);
        } else if (this.page.front.pageNumber >= this.currentPage){
          this.handleTurn(false);
        } else {
          this.handleTurn(false);
        }
      }
    },
    handleTurn (justTurned) {
      if (justTurned) {
        this.turned = true
        setTimeout(() => {
          this.zIndex = this.index
        }, 300)
      } else {
        this.turned = false
        setTimeout(() => {
          this.zIndex = this.total - this.index;
        }, 300)
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.double-sided-page{
  position: absolute;
  left:50%;
  top:0;
  width: 50%;
  height:100%;
  transform-style: preserve-3d;
  transform: translate3d(0,0,0) rotateY(0);
  transform-origin: left bottom;
  transition: transform 1s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  .illustration{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200%;
  }
  .text{
    position: relative;
    z-index: 1;
    display: inline-block;
    top:50%;
    left:50%;
    transform: translateX(-50%) translateY(-50%);
    color:black;
  }
  .front{
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    backface-visibility: hidden;
    transform: translate3d(0,0,0);
    background-color: #fdfcfb;
    overflow: hidden;
    .turn-shadow{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transform-origin: left bottom;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(9,9,121,0) 100%);
      transform: translate3d(0, 0, 0) scaleX(0.5);
      // transition: all 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
      transition: all 1s ease-in-out;
      opacity: 0;
    }
    .illustration{ background-position: 100% center; }
  }
  .back{
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    backface-visibility: hidden;
    transform: translate3d(0,0,0) rotateY(180deg);
    background-color: #fdfcfb;
    .turn-shadow{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transform-origin: right bottom;
      background: linear-gradient(-90deg, rgba(0, 0, 0, 0.8) 0%, rgba(9,9,121,0) 100%);
      transform: translate3d(0, 0, 0) scaleX(1);
      // transition: all 1.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
      transition: all 0.4s ease-in-out;
      opacity: 0.4;
    }
    .illustration{ background-position: 0% center; }
    .signature{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      z-index: 1;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &.turned{
    transform: translate3d(0,0,0) rotateY(-180deg);
    .front{
      .turn-shadow{
        transition: all 0.4s ease-in-out;
        transform: translate3d(0, 0, 0) scaleX(2);
        opacity: 0.4;
      }
    }
    .back{
      .turn-shadow{
        transition: all 1s ease-in-out;
        transform: translate3d(0, 0, 0) scaleX(0.5);
        opacity: 0;
      }
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>