<template>
  <div class='toad-joinus'>
    <div class='toad-joinus__left'></div>
    <div class='toad-joinus__right'>
      <div class='toad-joinus__right__content'>
        <img class='toad-joinus__right__content__thlogo' src="./../assets/Cloudman-th-logo.png" alt="cloud-man-toad-house-logo">
        <h2>The next generation of children's book publishing is just around the corner.</h2>
        <p>It's time to tip our hat to the old and foster the new. Join our email list to get notified when we launch.</p>
        <div class='toad-joinus__right__content__form'>
          <div class='toad-joinus__right__content__form__wrap'>
            <form action="https://hellotoad.us1.list-manage.com/subscribe/post?u=1d79166b0cd2fb5e85dc8b60b&amp;id=399911f44a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
              <input 
                id="mce-EMAIL"
                class='toad-joinus__right__content__form__wrap__input required email' 
                type="email" 
                value=""
                name="EMAIL"
                placeholder='Email address' /> 
              <button 
                class='toad-joinus__right__content__form__wrap__button' 
                type="submit"><span class='emoji'>👋</span> Sign Up</button>
            </form>
              <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style="display:none"></div>
                <div class="response" id="mce-success-response" style="display:none"></div>
              </div>    
              <div style="position: absolute; left: -5000px;" aria-hidden="true">
                <input 
                type="text" 
                name="b_1d79166b0cd2fb5e85dc8b60b_399911f44a" 
                tabindex="-1" 
                value="" />
              </div>
          </div>
        </div>
      </div>

      <div class='toad-joinus__right__social'>
        <span class='toad-joinus__right__social__link-icon' @click='goTo("https://www.instagram.com/toadhouseinc")' target='blank'>
          <img src="./../assets/instagram-icon.svg" alt="instagram">
        </span>
        <span class='toad-joinus__right__social__link-text' @click='goTo("https://www.instagram.com/toadhouseinc")' target='blank'>Follow us on Instagram</span>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      inputValue: null
    }
  },
  methods: {
    handleInputChange () {
      console.log('typing');
    },
    goTo (url) {
      window.location.href = url;
    }
  }
}
</script>
<style lang='scss' scoped>
  .toad-joinus{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    overflow: hidden;
    *{
      box-sizing: border-box;
    }
    &__left{
      background-color: #F5F5F5;
      position: absolute;
      top:0;
      left:0;
      width:30%;
      height:100%;
      background-image: url("./../assets/Cloudman-th-logo.png");
      background-position: center;
      background-size: 80%;
      background-repeat: no-repeat;
      @include respond-to('medium'){
        display: none;
      }
    }
    &__right{
      background-color: $color-bg-dark;
      position: absolute;
      top:0;
      left:30%;
      width:70%;
      height:100%;
      padding:5rem;
      overflow-y:scroll;
      min-height: 560px;
      @include respond-to('medium'){
        left:0%;
        width:100%;
        padding:2rem;
      }
      &__content{
        position: relative;
        margin: 0 auto;
        text-align: center;
        padding-top: 6rem;
        @include respond-to('medium') {
          min-height: none;
          padding-top: 2rem;
        }
        &__thlogo{
          display: none;
          margin-bottom: 1.5rem;
          @include respond-to('medium') {
            display: block;
            max-width:230px;
            height:auto;
            position: relative;
            margin:0 auto;
            margin-bottom: 1.5rem;
          }
        }
        h2{
          font-size: 2.3rem;
          max-width: 29rem;
          position: relative;
          margin: 0 auto;
          margin-bottom: 1.5rem;
          line-height: 2.6rem;
          @include respond-to('small'){
            font-size: 1.7rem;
            line-height: 2.3rem;
          }
          @include respond-to('medlarge'){
            font-size: 2rem;
            line-height: 2.3rem;
          }
        }
        p{
          position: relative;
          margin:0 auto;
          max-width: 29rem;
          margin-bottom: 4rem;
          @include respond-to('medlarge'){
            font-size: 0.9rem;
            line-height: 1.2rem;
          }
        }
        &__form{
          display: inline-block;
          margin-bottom: 3rem;
          @include respond-to('medium'){
            display: block;
          }
          &__wrap{
            position: relative;
            margin:0 auto;
            form{
              background-color: #F5F5F5;
              border-radius: 0.35rem;
              display: flex;
              align-items: center;
              padding-right: 3px;
            }
            &__input{
              display: block;
              background-color: transparent;
              width: 15rem;
              padding: 9px 9px 9px 20px;
              &.email{
                color:#1B2733;
              }
              @include respond-to('medium'){
                font-size: 0.9rem;
                width: 100%;
              }
            }
            &__button{
              top: 0;
              padding: 0.35rem 0.7rem;
              border: none;
              border-radius: 0.3rem;
              background-color: $color-bg-dark;
              color: #F5F5F5;
              font-size: 0.9rem;
              transition:all 0.2s ease-in-out;
              .emoji{
                margin-right:0.3rem;
              }
              @include respond-to('medium'){
                font-size: 0.8rem;
              }
              &:focus, &:hover{
                background-color: #2a3b4c;
              }
            }
          }
        }
      }
      &__social{
        position: relative;
        width: 100%;
        height: 5.5rem;
        bottom: 0;
        left: 0;
        text-align: center;
        padding-bottom: 2rem;
        margin-top: 4rem;
        @include respond-to('medium'){
          position: relative;
        }
        span{
          position: relative;
          margin:0 auto;
          display: block;
          width:10rem;
          cursor: pointer;
        }
        &__link-icon{
          margin-bottom: 0.5rem !important;
          img{
            width:1.6rem;
            height:auto;
            display: block;
            position: relative;
            margin:0 auto;
          }
        }
        &__link-text{
          font-size: 0.85rem;
          color: white;
        }
      }
    }
  }
</style>
