<template>
  <div id='toad-home' :class='layout'>
    <toad-nav 
      v-if='user'
      @profile-click='showProfileSheet = true' 
      :displayName='user.firstName'
    />
    <f7-swiper
      v-if='allBooks && layout === "tablet"' 
      class='books' 
      :class='{loading: images.loading}'
      :params="{
        slideChange: handleSlideChange,
        speed:500, 
        slidesPerView: 1.75, 
        centeredSlides: true, 
        dataSpaceBetween: 90,
        slideToClickedSlide: true,
        freeModeMomentumBounce: false,
        freeModeMinimumVelocity: 0.08,
      }"
    >
      <f7-swiper-slide 
        class='books__item' 
        v-for='(book, b) in allBooks' 
        :key='`b-item-${book.slug}-${b}`'>
        
        <img 
          class='books__item__cover' 
          :src="BOOK_BUCKET_URL(book.slug, book.assets.cover.img)" 
          @load="handleImageLoad"/>

        <div class='books__item__options'>
          <lp-button 
            text='Read Now' 
            buttonType='secondary' 
            icon='📖'
            class='btn-margin-r-lg'
            @click='handleReadNow(book)'
          />
          <lp-button 
            text='More Details' 
            buttonType='primary' 
            @click='showBookDetail(book.slug)'
          />
        </div>

      </f7-swiper-slide>
    </f7-swiper>
    <div 
      v-if='allBooks && layout === "mobile"' 
      class='books'
      :class='{loading: images.loading}'>
      <div 
        class='books__item' 
        v-for='(book, b) in allBooks' 
        :key='`b-item-${book.slug}-${b}`'>
        
        <img 
          class='books__item__cover' 
          :src="BOOK_BUCKET_URL(book.slug, book.assets.cover.img)" 
          @load="handleImageLoad"/>

        <div class='books__item__options'>
          <lp-button 
            text='Read Now' 
            buttonType='secondary' 
            icon='📖'
            class='btn-margin-r-lg'
            @click='$router.push(`/book/${book.slug}`)'
          />
          <lp-button 
            text='More Details' 
            buttonType='primary' 
            @click='showBookDetail(book.slug)'
          />
        </div>

      </div>
    </div>

    <div class='skeleton-group' v-if='images.loading && this.user'>
      <div v-for='i in 3' :key='`skeleton-item-${i}`' class='skeleton-group__item'>
        <div class='skeleton-group__item__book'></div>
        <div class='skeleton-group__item__options'>
          <div class='skeleton-group__item__options__button'></div>
          <div class='skeleton-group__item__options__button'></div>
        </div>
      </div>
    </div>


    <lp-action-sheet 
      @closed='showBookDetailSheet=false' 
      :openState='showBookDetailSheet'
      sheetTitle='📙 About the book'>

      <div class="sheet-modal-swipe-step">
        <div class="padding" v-if='selectedBookDetails'>
        <!-- <div class="padding"> -->
          <h3 class='text-align-center'>{{selectedBookDetails.title}}</h3>
          <p>{{selectedBookDetails.byline}}</p>
          <p>{{selectedBookDetails.shortSummary}}</p>
          <p>hi there how are you?</p>
        </div>
        <div class="padding-horizontal padding-bottom">
          <div class="margin-top text-align-center">Swipe up for more details</div>
        </div>
      </div>
      <f7-block-title medium class="margin-top">
        <div v-if='selectedBookDetails' v-html='selectedBookDetails.summary'></div>
        <!-- <p>hi, how are you?</p> -->
      </f7-block-title>
      
    </lp-action-sheet>

    <lp-action-sheet 
      @closed='showProfileSheet=false' 
      :openState='showProfileSheet'
      sheetTitle='Profile'>

      <div class="sheet-modal-swipe-step">
        <div class="padding">
          <p @click='logOut'>Logout</p>
        </div>
        <div class="padding-horizontal padding-bottom">
          <div class="margin-top text-align-center">Swipe up for more details</div>
        </div>
      </div>
      <f7-block-title medium class="margin-top">
        <p>Hello!</p>
      </f7-block-title>
      
    </lp-action-sheet>
    <transition name="modal">  
      <book-modal v-if='showContinueReadingModal'>
        <template v-slot:body>
          <h2>Continue on from where you left off?
          </h2>
          <lp-button 
            text='Continue Reading' 
            buttonType='secondary' 
            icon='🚎' 
            @click='openInProgressBook("from-current")'
            />
          <lp-button 
            text='Start over' 
            buttonType='clearLight' 
            @click='openInProgressBook("from-start")'
            />
        </template>
      </book-modal>
    </transition>
  </div>
</template>

<script>
import firebase from "firebase";
import {debounce} from 'lodash';
import { mapActions, mapState } from 'vuex';
import { BOOK_BUCKET_URL } from './../api/endpoints';
import ToadNav from './../components/ToadNav.vue';
import LpButton from './../components/lilypad/LpButton.vue';
import LpActionSheet from './../components/lilypad/LpActionSheet.vue';
import BookModal from './../components/BookModal.vue';
export default {
  name: 'Home',
  components: {
    ToadNav, 
    LpActionSheet,
    LpButton,
    BookModal
  },
  data () {
    return {
      user: null,
      BOOK_BUCKET_URL,
      showBookDetailSheet: false,
      showProfileSheet: false,
      showContinueReadingModal: false,
      selectedBookDetails: null,
      handleReadNowBook: null,
      slideHeight: null,
      layout: 'tablet',
      images: {
        loadedCount: 0,
        loading: true
      }
    }
  },
  computed: {
    ...mapState('books', ['allBooks'])
  },
  created () {
    // screen.orientation.lock('portrait');   
    firebase.auth().onAuthStateChanged((user) => {
      console.log('auth state check');
      console.log(user);
      if (user) {
        this.GET_PROFILE(user.uid)
          .then(profile => {
            console.log(profile);
            this.user = profile;
            this.GET_ALL_BOOKS();
          })
          .catch(err => {
            console.log(err);
          });
        // User is signed in.
      } else {
        // No user is signed in.
        this.$router.push({name: 'login'});
      }
    });
  },
  destroyed () {
    window.removeEventListener('resize', 
      debounce(() => { 
        this.bindMediaQueryWatcher(); 
      }, 100)
    );
  },
  mounted () {
    screen.orientation.lock('portrait');
    this.bindMediaQueryWatcher();
    window.addEventListener('resize', 
      debounce(() => { 
        this.bindMediaQueryWatcher(); 
      }, 100)
    );
  },
  methods: {
    ...mapActions('books', ['GET_ALL_BOOKS', 'GET_BOOK']),
    ...mapActions('users', ['GET_PROFILE']),
    openInProgressBook (fromWhere) {
      // console.log(this.user.books[this.handleReadNowBook.id])
      if (fromWhere === "from-current") {
        this.$router.push(`/book/${this.handleReadNowBook.slug}?page=${this.user.books[this.handleReadNowBook.uid].currentPage}`);
      } else if (fromWhere === "from-start") {
        this.$router.push(`/book/${this.handleReadNowBook.slug}?page=1`);
      }
      this.handleReadNowBook = null;
    },
    handleReadNow (book) {
      console.log(book);
      console.log(this.user.books);
      if (this.user.books[book.uid]) {
        if (this.user.books[book.uid].readingStatus === 'in-progress' && (this.user.books[book.uid].currentPage > 2)) {
          this.showContinueReadingModal = true;
          this.handleReadNowBook = book;
        } else {
          this.$router.push(`/book/${book.slug}`);
        }  
      } else {
        this.$router.push(`/book/${book.slug}`);
      }
    },
    handleSlideChange () {
      console.log(this.$refs.bookListSwiper.swiper.activeIndex);
    },
    handleImageLoad () {
      this.images.loadedCount++
      console.log(this.images.loadedCount)
      console.log(this.allBooks.length);
      if (this.images.loadedCount === this.allBooks.length) {
        setTimeout(() => {
          this.images.loading = false;    
        }, 400)
        
        console.log('done loading');
      }
    },
    // returnFullBucketDirectoryPath (bookSlug) {
    //   let bucketUrl = this.GCS_BUCKET_URL;
    //   if (bookSlug) {
    //     bucketUrl = `${this.GCS_BUCKET_URL}/${bookSlug}/`;
    //   }
    //   return bucketUrl;
    // },
    bindMediaQueryWatcher () {
      const max700 = window.matchMedia("(max-width: 576px)");
      if (max700.matches) {
        this.layout = 'mobile';
        console.log('were inside 700.');
      } else {
        this.layout = 'tablet';
      }
    },
    showBookDetail (bookSlug) {
      const selectedBook = this.allBooks.filter(book => book.slug === bookSlug);
      this.selectedBookDetails = selectedBook[0]; 
      setTimeout(() => {
        this.showBookDetailSheet = true;
      }, 0)
    },
    logOut() {
      this.showProfileSheet = false;
      firebase.auth().signOut().then(() => {
        firebase.auth().onAuthStateChanged(() => {
          this.$router.push({name: 'login'});
        })
      });
    }
    // onPageBeforeOut() {
    //   const self = this;
    //   // Close opened sheets on page out
    //   self.$f7.sheet.close();
    // },
    // onPageBeforeRemove() {
    //   const self = this;
    //   // Destroy sheet modal when page removed
    //   if (self.sheet) self.sheet.destroy();
    // }
  }
}
</script>

<style lang='scss'>
#toad-home {
  height:100%;
  display: flex;
  flex-direction: column;
  .button{
    font-size: 0.95rem;
    height: unset;
    padding: 0.6rem;
    text-transform: none;
    font-weight: bold;
    &.buy{
      background-color:#8379F5;
      color:#1B2733;
    }
  }
  .block{
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .skeleton-group{
    display: flex;
    position: absolute;
    padding: 2rem;
    top:20%;
    left:50%;
    transform: translateX(-50%);
    @include respond-to('small'){
      flex-direction: column;
      width: 100%;
      box-sizing: border-box;
      top:30%;
    }
    &__item{
      // position: absolute;
      // top: 45%;
      // left: 0;
      width: 33%;
      margin-right: 2rem;
      // height: 40%;
      // transform: translateY(-50%);
      &:nth-of-type(3n){
        margin-right:0;
      }
      @include respond-to('small'){
        width:100%;
        margin-right: 0;
        margin-bottom: 2rem;
      }
      &__book{
        display: block;
        width: 100%;
        height: 0;
        padding-top: 81.25%;
        margin: 0 auto;
        background-color: #28394a;
        border-radius: 0.3rem;

        animation-duration: 1.8s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background: #243342;
        background: linear-gradient(to right, #243342 8%, #2c3e50 38%, #243342 54%);
        background-size: 1000px 640px;
        
        position: relative;
      }
      &__options{
        position: relative;
        margin:0 auto;
        display: flex;
        justify-content: center;
        margin-top: 1rem;
        @include respond-to('small'){
          margin-top: 0.5rem;
        }
        &__button{
          margin:1rem;
          height:3rem;
          width:8rem;
          border-radius: 6rem;

          animation-duration: 1.8s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: placeHolderShimmer;
          animation-timing-function: linear;
          background: #243342;
          background: linear-gradient(to right, #243342 8%, #2c3e50 38%, #243342 54%);
          background-size: 1000px 640px;
          @include respond-to('medium'){
            height:2rem;
            margin:0.5rem;
          }
          @include respond-to('small'){
            height:3rem;
            margin:0.85rem;
          }
        }
      }
    }
  }
  &.tablet{
    .books{
      width: 100%;
      transition: all 0.8s ease-in-out;
      &:after {
        content: "";
        flex: 0 0 1rem;
      }
      &__item{
        width:80%;
        &__cover{
          display: block;
          max-width: 100%;
          max-height: calc(100% - 7rem);
          margin: 0 auto;
          transform: scale(1.0);
          transition:transform 0.2s ease-in-out;
        }
        &.swiper-slide-active{
          .books__item__cover{
            transform: scale(1.1);
          }
        }
        &.swiper-slide-active{
          .books__item__options{
            opacity: 1;
          }
        }
        &__options{
          text-align: center;
          margin-top: 2rem;
          padding-bottom: 3rem;
          opacity: 0;
          transition:all 0.2s ease-in-out;
        }
      }
      &.loading{
        visibility: hidden;
        opacity: 0;
      }
    }
  }
  &.mobile{
    overflow-y: scroll;
    transition:all 0.2s ease-in-out;
    .books{
      padding: 2rem;
      box-sizing: border-box;
      width: 100%;
      transition: all 0.8s ease-in-out;
      &__item{
        &__cover{
          display: block;
          width: 100%;
          margin: 0 auto;
        }
        &__options{
          text-align: center;
          margin-top: 2rem;
          padding-bottom: 3rem;
          @include respond-to('small'){
            margin-top:1rem;
            padding-bottom: 2rem;
          }
        }
      }
      &.loading{
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  .book{
    img{
      position: relative;
      width:100%;
      display: block;
      margin-bottom: -3rem;
    }
    .book-details{
      padding: 3rem 1rem 1rem;
      background-color: #334150;
      border-radius: 30px 30px 0px 0px;
    }
  }
}
@keyframes placeHolderShimmer{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}
.modal-enter-active, .modal-leave-active {
  transition: all .25s;
  // transform: translateY(0px) scale(1);
  opacity: 1;
}
.modal-enter, .modal-leave-to{
  opacity: 0;
  // transform: translateY(10px) scale(0.9);
}
</style>
