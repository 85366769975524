<template>
  <div class='book-body' v-if='book'>
    <double-sided-page
      v-for='(page, n) in paginatedBookBody'
      :page='page'
      :index='n'
      :total='book.body.length'
      :key='n'
      :currentPage='currentPage'
      @pageClick='(page) => { $router.push({ query: { page: page } })}'/>
  </div>
</template>
<script>
import {mapState} from 'vuex';
import DoubleSidedPage from './DoubleSidedPage.vue';
import _ from 'lodash';
export default {
  components: {
    DoubleSidedPage
  },
  props: ['book', 'currentPage'],
  computed: {
    ...mapState('activity', ['profileBook']),
    paginatedBookBody () {
      console.log(this.book);
      let pgs = [];

      if (this.book && this.profileBook) {
        const localBookCopy = _.cloneDeep(this.book);
        // if the profile book has a signature image
        // reveal the signature page
        // otherwise, remove the spread from collation
        // if (this.profileBook.signing.signingImage) {
        //   localBookCopy.body[0].image = this.profileBook.signing.signingImage;
        // } else {
        //   localBookCopy.body.shift();
        // }

        localBookCopy.body.forEach((spread, s) => {
          const { pages: { left }, image } = spread;
          const previousIndex = s - 1;
          // initial body, but missing last page:
          pgs.push({
            front: {
              pageNumber: left.pageNumber - 1,
              text: s > 0 ? localBookCopy.body[previousIndex].pages.right.text : '',
              img: s > 0 ? localBookCopy.body[previousIndex].image : '',
              colophon: s > 0 ? localBookCopy.body[previousIndex].pages.right.colophon : ''
            },
            back: {
              pageNumber: left.pageNumber,
              text: left.text,
              img: image,
              colophon: left.colophon
            }
          });
        });
        pgs.push({
          front: {
            pageNumber: localBookCopy.body[localBookCopy.body.length - 1].pages.right.pageNumber,
            text: localBookCopy.body[localBookCopy.body.length - 1].pages.right.text,
            img: localBookCopy.body[localBookCopy.body.length - 1].image,
          },
          back: {
            pageNumber: localBookCopy.body[localBookCopy.body.length - 1].pages.right.pageNumber+1,
            text: '',
            img: '',
          }
        });
        console.log(pgs);
      }
      return pgs;
    }
  },
  methods: {    
    handlePageRoute (page) {
      const query = Number(this.$route.query.page);
      if (query && query !== page) {
        this.$router.push({ query: { page } })  ;
      }      
    }
  }
}
</script>
<style lang='scss' scoped></style>