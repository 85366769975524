<template>
  <div 
    class='toad-avatar' 
    :style='avatarStyle'>
    <div v-if='!pictureUrl' class='toad-avatar__default'>👤</div>
    <svg v-if='notifications' xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20">
      <circle class='toad-avatar__notification-dot' cx="10" cy="10" r="8" stroke-width="4" />
    </svg> 
  </div>
</template>
<script>
export default {
  props: {
    pictureUrl: {
      type: String,
      required: false,
      default: null
    },
    notifications: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    avatarStyle () {
      let styleProp = ``;
      if (this.pictureUrl) {
        styleProp = `background-image:url(${this.pictureUrl});`;
      }
      return styleProp
    }
  }
}
</script>
<style lang='scss' scoped>
.toad-avatar{
  position: relative;
  width: 56px;
  height: 56px;
  background-color: white;
  border-radius: 33px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &__default{
    position: relative;
    text-align: center;
    top:50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
  }
  svg{
    position: absolute;
    top:-2px;
    right:-2px;
  }
  &__notification-dot{
    fill: #6FCF97;
    stroke: #334150;
  }
}
</style>