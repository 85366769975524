<template>
  <div id='toad-admin' class='t-admin-book-page' v-if='book'>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      :title="book.title"
      :sub-title="book.byline"
      @back="() => $router.push('/admin')"
    />
    <div class='book-container'>
      <nav>
        <a-radio-group v-model="radioValue" class='radio-selects'>
          <a-radio-button value="about">
            About
          </a-radio-button>
          <a-radio-button value="signings">
            Signings
          </a-radio-button>
        </a-radio-group>
      </nav>

      <div class='content'>
        
        <div v-show='radioValue === "about"'>
          <div class='meta'>
            <div class='cover'>
              <img :src="BOOK_BUCKET_URL($route.params.bookSlug, book.assets.cover.img)" alt="">
            </div>
            <div class='info'>
              <p>published on: {{book.datePublished}}</p>
              <p>slug: {{book.slug}}</p>
              <p>page count: {{book.body.length * 2}}</p>
            </div>
          </div>
          <div v-html='book.summary'></div>
        </div>

        <div v-show='radioValue === "signings"'>

          <a-button type="primary" @click="signingModal = true">
            New {{book.title}} Signing Event
          </a-button>  

          <a-modal
            class='new-signing-modal'
            title="New Signing Event"
            :visible="signingModal"
            :confirm-loading="confirmLoading"
            @ok="postBookSigningEvent"
            @cancel="handleCancel"
          >
            <p>To create a new signing event for {{book.title}}, choose a date and the number of signings you'd like to offer on that day.</p>
            <div class='signing-form-field'>
              <a-date-picker
                id='signing-date-input'
                v-model="form.date"
                type="date"
                placeholder="Select a signing date.."
                style="width: 100%;"
              />
            </div>
            <div class='signing-form-field'>
              <a-input id='slot-count-input' addon-before="Signing Slots:" placeholder="Number of slots.." v-model='form.availableSlots' />
            </div>
          </a-modal>

          <div class='book-signing-events' v-if='bookSigningEvents'>
            
            <div class='signings-nav'>
              <p>You currently have {{bookSigningEvents.length}} events scheduled.</p>
            </div>


            <div
              v-for='(signingEvent, s) in bookSigningEvents' 
              :class='`signing-event ${signingEvent.submissions.length < 1 ? "empty" : ""}`'
              :key='`book-signing-${s}-${signingEvent.bookUid}`' 
              @click='openSigningEvent(signingEvent)' >

              <h3>{{`${readableDate(signingEvent.date)}`}}</h3>
              <p>{{ `${signingEvent.submissions.length}/${signingEvent.availableSlots} slots filled` }}</p>

              <a-popover title="Title" trigger="click">
                <template slot="content">
                  <p>Delete</p>
                </template>
                <a-icon type="more" @click.stop />
              </a-popover>

            </div>
          </div>
          <p v-else>No book signing events for this book.</p>

        </div>
        <a-modal
          class='signing-event-details'
          title="Signing Event Details"
          :visible="signingEventModal"
          v-if='currentSigningEvent'
          :confirm-loading="uploadLoading"
          @ok="uploadToFirebase()"
          @cancel='signingEventModal = false'
        >
          <div v-if='currentSigningEvent.submissions.length > 0'>
            <div class='submission' v-for='(submission, s) in currentSigningEvent.submissions' :key='`submission-item-${s}`'>
              <h3>
                {{s+1}}. <span class='submission-name' v-for='(item, i) in submission.signingTo' :key='`name-item-${i}`'>{{item.name}}</span>
              </h3>
              <div class='current-image' v-if='submission.signingImage'>
                <p>Current signature image:</p>
                <div 
                  class='signing-img' 
                  :style="`background-image:url(${submission.signingImage});`" 
                  @click='newImageTab(submission.signingImage)'/>
              </div>
              <p v-else>No signature image added yet.</p>

              <div class='upload-controls'>
                <input type="file" @change="(e) => { handleFileInput(e, submission.userId, currentSigningEvent.BookSigningEventUid) }" accept="image/x-png,image/jpeg" />
                <!-- <button @click='uploadToFirebase(submission.userId, currentSigningEvent.BookSigningEventUid)'>Upload to Firebase</button> -->                
              </div>

            </div>
          </div>
          <p v-else>No signing submissions yet..</p>
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>
import {storage} from "./../../config";
import { 
  API_BOOK, 
  BOOK_BUCKET_URL, 
  API_BOOK_SIGNING_EVENTS,
  API_BOOK_SIGNING_EVENTS_BY_BOOK,
  API_BOOK_SIGNING_EVENTS_ADD_SIGNATURE
} from './../api/endpoints';
import axios from 'axios';
import moment from 'moment';
export default {
  data () {
    return {
      book: null,
      bookSigningEvents: null,
      // activeKey: null,
      BOOK_BUCKET_URL,
      currentSigningEvent: null,
      radioValue: 'about',
      signingModal: false,
      signingEventModal: false,
      confirmLoading: false,
      uploadLoading: false,
      signatureImage: null,
      other: '',
      form: {
        date: null,
        availableSlots: null
      },
      uploadDetails: {
        userId: null,
        BookSigningEventUid: null
      }
    }
  },
  created () {
    this.getBook(this.$route.params.bookSlug)
      .then(bookResponse => {
        console.log(bookResponse);
        this.book = bookResponse;

        this.getBookSigningEvents(bookResponse.uid)
          .then(bookSigningEventsResponse => {
            this.bookSigningEvents = bookSigningEventsResponse;
          })
          .catch(err => { 
            console.log(err.response) 
          });
      })
      .catch(err => { console.log(err) });
  },
  methods: {
    uploadToFirebase () {
      console.log(this.signatureImage);
      this.uploadLoading = true;
      if (this.signatureImage) {
        let fileExtension = '.png';
        if (this.signatureImage.type === 'image/jpeg') {
          fileExtension = '.jpg';
        }
        const filePathAndName = `user-${this.uploadDetails.userId}/${this.$route.params.bookSlug}/signing-image${fileExtension}`;
        const storageRef = storage.ref();
        const imageRef = storageRef.child(filePathAndName);
        imageRef.put(this.signatureImage)
         .then(() => {
            console.log("Signature image uploaded successfully to Firebase.");
            
            storageRef.child(filePathAndName).getDownloadURL()
              .then((url) => {

                axios.post(API_BOOK_SIGNING_EVENTS_ADD_SIGNATURE(this.book.uid),
                  {
                    signingSubmission: {
                      BookSigningEventUid: this.uploadDetails.BookSigningEventUid,
                      userId: this.uploadDetails.userId,
                      imageUrl: url
                    }
                  })
                  .then(resp => {
                    console.log(resp);
                    this.uploadLoading = false;
                    this.signingEventModal = false;
                    
                    this.uploadDetails = {
                      userId: null,
                      BookSigningEventUid: null
                    };

                    this.getBookSigningEvents(this.book.uid)
                      .then(bookSigningEventsResponse => {
                        this.bookSigningEvents = bookSigningEventsResponse;
                      })
                      .catch(err => { 
                        console.log(err.response) 
                      });
                  })
                  .catch(err => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err)
              });
          });
      } else {
        alert("Please select a signature image (jpeg or png) first.");
      }
    },
    handleFileInput (e, userId, BookSigningEventUid) {
      console.log(e.target.files);
      const reader = new FileReader();
      let file = e.target.files[0];

      this.uploadDetails = { userId, BookSigningEventUid };

      if (file) {
        reader.onload = () => {
          if (reader.readyState === 2) {
            console.log(file);
            this.signatureImage = file;
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        this.signatureImage = null;
      }
    },
    getBook (slug) {
      return new Promise((resolve, reject) => {
        axios.get(API_BOOK(slug))
          .then(resp => {
            console.log(resp);
            resolve(resp.data[0]);
          })
          .catch(err => {
            console.log(err);
            reject();
          });
      })
    },
    getBookSigningEvents (bookUid) {
      return new Promise((resolve, reject) => {
        axios.get(API_BOOK_SIGNING_EVENTS_BY_BOOK(bookUid))
          .then(resp => {
            console.log(resp);
            resolve(resp.data);
          })
          .catch(err => {
            console.log(err);
            reject(err);
          });
      })
    },
    postBookSigningEvent () {

      console.log(moment(this.form.date).format());
      console.log(this.form.availableSlots);
      // this.getAllBooks();
      
      if (this.form.date && this.form.availableSlots) {
        this.confirmLoading = true;
        const bookSigningEvent = {
          bookUid: this.book.uid, 
          date: moment(this.form.date).format(), 
          availableSlots: Number(this.form.availableSlots)
        }
        axios.post(API_BOOK_SIGNING_EVENTS, {bookSigningEvent})
          .then(() => {
            // this.getAllBooks();
            this.signingModal = false;
            this.confirmLoading = false;
            this.form.date = null;
            this.form.availableSlots = null;
            this.getBookSigningEvents(this.book.uid)
              .then(bookSigningEventsResponse => {
                this.bookSigningEvents = bookSigningEventsResponse;
              })
              .catch(err => { 
                console.log(err.response) 
              });
          })
          .catch(err => {
            console.log(err);
            this.loading = false;
          })
      }
    },
    handleCancel() {
      this.signingModal = false;
    },
    readableDate(utcDate) {
      return moment(utcDate).format('MMMM Do, YYYY');
    },
    handleSigningEventClick (event) {
      event.stopPropagation();
    },
    newImageTab(imageUrl) {
      window.open(imageUrl, '_blank');
    },
    openSigningEvent (signingEvent) {
      console.log('hi');
      this.signingEventModal = true;
      this.currentSigningEvent = signingEvent;
    }
  }
}
</script>
<style lang='scss'>
.t-admin-book-page{
  .book-container{
    max-width:60rem;
    margin:0 auto;
    padding:2.5rem 5rem;
    nav{
      // text-align: center;
      margin-bottom:4rem;
    }

    .content{
      .meta{
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        .cover{
          max-width: 50%;
          img{
            width:100%;
            height:auto;
          }
        }
        .info{}
      } 
    }
  }
}
.book-signing-events{
  .signings-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #edecf1;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    p{
      margin:0;
    }
  }
  .signing-event{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border:1px solid #edecf1;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.09);
    border-radius: 3px;
    padding:1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    h3{
      margin:0;
      margin-right: auto;
    }
    p{
      margin:0;
      margin-right: 10px;
    }
    i{
      padding:5px;
    }
    &:hover{
      background-color: #f4f6ff;
    };
  }
  .book-signing-item{
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.09);
  }
}
.signing-event-details{
    .submission{
      margin:1.5rem 0;
      border: 1px solid #dbdbdb;
      border-radius: 3px;
      padding: 1rem;
      .signing-img{
        display: inline-block;
        vertical-align: middle;
        width:4rem;
        height:4rem;
        border-radius: 3rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        margin-bottom: 20px;
      }
      .upload-controls{
        input{
          display: block;
          margin-bottom: 5px;
        }
      }
    }
    .submission-name{
      background-color: white;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.08);
      margin-right: 0.5rem;
      padding: 0.3rem 0.6rem;
      border-radius: 0.2rem;
      border: 1px solid #cecece;
    }
  }
.signing-form-field{
  margin:1rem 0;
}
</style>