<template>
  <f7-page id='toad-login'>
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-list class='toad-form'>
            <lp-input 
              :inputValue='email'
              @input='(inputValue) => handleInputChange("email", inputValue)'
              inputPlaceholder='Email'
              inputType='email'
              helperText='Login with your Email' />
          </f7-list>
        </f7-col>
      </f7-row>
      <f7-row>
        <lp-button 
          @click='handleResetPassword' 
          text='Reset Password'
          buttonType='primary'
        />
      </f7-row>  
    </f7-block>
  </f7-page>
</template>
<script>
import firebase from "firebase";
import LpButton from './../components/lilypad/LpButton.vue';
import LpInput from './../components/lilypad/LpInput.vue';
export default {
  components: {
    LpInput, LpButton
  },
  data () {
    return {
      email: null
    }
  },
  methods: {
    handleInputChange(inputType, inputValue) {
      this[inputType] = inputValue;
    },
    handleResetPassword() {
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
            alert('Check your registered email to reset the password!')
            this.email = null
        }).catch((error) => {
          alert(error)
        })
    }
  }
}
</script>
<style lang='scss'>
  .toad-form{
    background-color: white;
  }
</style>