<template>
  <div class='book-nav' v-if='book'>
    <div class='nav-wrap'>
      <h2>Jump to Page</h2>
      <div class='spreads'>
        <div
          class='spread'
          v-for='(spread, s) in book.body'
          :key='`${s}-pageindex`'
          @click='$emit("spreadClick", spread)'>
          <img :src="`${BOOK_BUCKET_URL($route.params.bookSlug, spread.image)}`">
          <span class='spread-title'>{{spread.pages.left.pageNumber}} - Page Name</span>
        </div>
      </div>
      <div class='nav-options'>
        <lp-button 
          text='Close Book' 
          buttonType='secondary' 
          icon='❌'
          class='btn-margin-r-lg'
          @click='$emit("closeBookClick")'
        />
        <lp-button 
          text='Book Details' 
          buttonType='primary' 
          @click='$emit("bookDetailBtnClick")'
        />
      </div>
    </div>
  </div>
</template>
<script>
import LpButton from './../components/lilypad/LpButton.vue';
import { BOOK_BUCKET_URL } from './../api/endpoints';
export default{
  components: {
    LpButton
  },
  props: ['book', 'currentPage'],
  data () {
    return {
      BOOK_BUCKET_URL
    }
  }
}
</script>
<style lang='scss' scoped>
.book-nav{
  background-color: $color-page-bg;
  width:100%;
  height:100%;
  text-align: center;
  .nav-wrap{
    position: relative;
    top:50%;
    transform: translateY(-50%);
  }
  .spreads{
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 1.6rem 8rem 2rem 8rem;
    .spread{
      img{
        width:17rem;
      }
      .spread-title{}
    }
  }
  .nav-options{
    text-align: center;
    margin-top: 2rem;
  }
}
</style>