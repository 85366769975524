import axios from 'axios';
import { 
  API_SUBMIT_READER_SIGNING_SUBMISSION
} from './../../api/endpoints';

const state = () => ({
  data: null
})

const mutations = {
  SET_DATA (state, data) {
    state.data = data;
  }
}

const actions = {
  SUBMIT_SIGNING ({commit}, submissionPayload) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_SUBMIT_READER_SIGNING_SUBMISSION(submissionPayload.bookUid)}`, 
        {
          signingSubmission: submissionPayload.submission
        }
      )
        .then(resp => {
          console.log(resp.data);
          commit('SET_DATA', resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  }
}

const bookSigningEvents = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default bookSigningEvents;