import firebase from "firebase/app";
import "firebase/storage";

const fbApp = firebase.initializeApp({
  apiKey: "AIzaSyABYFxO0gABRgDt6yrL4ernFPI4mc4bMtY",
  authDomain: "toad-web-a2453.firebaseapp.com",
  projectId: "toad-web-a2453",
  storageBucket: "toad-web-a2453.appspot.com",
  messagingSenderId: "1004964547540",
  appId: "1:1004964547540:web:4821a94e7f76e21805e441",
  measurementId: "G-S6XSME7PHS"
});

// Get a reference to the storage service, export it for use
export const storage = firebase.storage(); 

export default fbApp;