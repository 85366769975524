import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from "firebase";
import Home from '@/pages/Home.vue';
import Book from '@/pages/Book.vue';
import LilyPad from '@/pages/LilyPad.vue';
import Signup from '@/pages/Signup.vue';
import Login from '@/pages/Login.vue';
import ForgotPassword from '@/pages/ForgotPassword.vue';
import VerifySignupEmail from '@/pages/VerifySignupEmail.vue';
import VerificationSent from '@/pages/VerificationSent.vue';
import JoinUs from '@/pages/JoinUs.vue';
import Admin from './../pages/Admin.vue';
import AdminBook from './../pages/AdminBook.vue';


Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

const routerWeb = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/join-the-list' },
    {
      path: '/join-the-list',
      name: 'joinUs',
      component: JoinUs,
      meta: { 
        guest: true,
        platform: 'web'
      }
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {requiresAuth: true}
    },
    {
      path: '/lilypad',
      name: 'lilypad',
      component: LilyPad
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: { guest: true }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { guest: true }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPassword
    },
    {
      path: '/verify-email',
      name: 'verify-email',
      component: VerifySignupEmail
    },
    {
      path: '/verification-sent',
      name: 'verification-sent',
      component: VerificationSent
    },
    {
      path: '/book/:bookSlug',
      name: 'book',
      component: Book,
      meta: {requiresAuth: true}
    },
    {
      path: '/admin',
      name: 'admin',
      component: Admin
    },
    {
      path: '/admin/books/:bookSlug',
      name: 'admin-book',
      component: AdminBook
    }
  ]
});


routerWeb.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        next();
        return
      } 
      next('/login');
    });
  } else {
    next()
  }
});

routerWeb.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('guest route matched.')
        // User is signed in.
        next('/home');
        return
      } 
      next();
    });
  } else {
    next();
  }
});


export { routerWeb };