<template>
  <f7-button 
    class='lp-btn' 
    :class='`${buttonType} ${!text ? "icon-only" : ""} ${disabled ? "disabled" : ""} ${loading ? "loading" : ""}`'
    fill 
    round
    @click='$emit("click")'>
    <span v-if='loading' class="lp-btn__loader">Loading...</span>
    <span v-else-if='icon' class='lp-btn__icon'>{{icon}}</span>
    {{text}}
  </f7-button>
</template>
<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        required: false,
        default: false
      },
      buttonType: {
        type: String,
        required: false,
        default: 'dark'
      },
      text: {
        type: String,
        required: false,
        default: null
      },
      icon: {
        type: String,
        required: false,
        default: null
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
<style lang='scss' scoped>
.lp-btn{
  padding: 19px 21px !important;
  height: unset !important;
  width: unset !important;
  min-width: unset !important;
  max-height: 53px;
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 15px !important;
  font-weight: bold !important;
  display: inline-block !important;
  background-color: $color-btn-dark !important;
  &.icon-only{
    padding: 19px 16px !important;
    width: 54px !important;
    height: 54px !important;
    overflow: visible;
  }
  &__icon{
    display: inline-block;
    vertical-align: middle;
    font-size: 22px;
    margin-right: 5px;
  }
  &__loader{
    display: inline-block;
    font-size: 10px;
    margin-right: 0.35rem;
    text-indent: -9999em;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    &:before{
      width: 50%;
      height: 50%;
      background: #ffffff;
      border-radius: 100% 0 0 0;
      position: absolute;
      top: 0;
      left: 0;
      content: '';
    }
    &:after{
      background: $color-bg-dark;
      width: 75%;
      height: 75%;
      border-radius: 50%;
      content: '';
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
  &.primary{
    background-color: $color-btn-dark !important;
    .lp-btn__loader{
      &:before{
        background: #ffffff;
      }
      &:after{
        background: $color-btn-dark;
      }
    }
  }
  &.secondary{
    background-color: $color-btn-light !important;
    color: #101F29;
    .lp-btn__loader{
      background: $color-bg-dark;
      background: -moz-linear-gradient(left, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      background: -webkit-linear-gradient(left, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      background: -o-linear-gradient(left, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      background: -ms-linear-gradient(left, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      background: linear-gradient(to right, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      &:before{
        background: $color-bg-dark;
      }
      &:after{
        background: #ffffff;
      }
    }
  }
  &.tertiary{
    background-color: $color-btn-green !important;
    color: #101F29;
    .lp-btn__loader{
      background: $color-bg-dark;
      background: -moz-linear-gradient(left, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      background: -webkit-linear-gradient(left, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      background: -o-linear-gradient(left, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      background: -ms-linear-gradient(left, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      background: linear-gradient(to right, $color-bg-dark 10%, rgba(255, 255, 255, 0) 42%);
      &:before{
        background: $color-bg-dark;
      }
      &:after{
        background: $color-btn-green;
      }
    }
  }
  &.clearLight{
    background-color: transparent !important;
    color: #ffffff;
    .lp-btn__loader{
      &:before{
        background: #ffffff;
      }
      &:after{
        background: $color-bg-dark;
      }
    }
  }
  &.clearDark{
    background-color: transparent !important;
    color: $color-text-dark;
    .lp-btn__loader{
      &:before{
        background: $color-bg-dark;
      }
      &:after{
        background: #ffffff;
      }
    }
  }
  &.disabled{
    opacity: 0.4 !important;
  }
  &.loading{
    pointer-events: none;
  }
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>