import { render, staticRenderFns } from "./BookNav.vue?vue&type=template&id=ade6f748&scoped=true&"
import script from "./BookNav.vue?vue&type=script&lang=js&"
export * from "./BookNav.vue?vue&type=script&lang=js&"
import style0 from "./BookNav.vue?vue&type=style&index=0&id=ade6f748&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ade6f748",
  null
  
)

export default component.exports