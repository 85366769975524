import axios from 'axios';
import { API_PROFILES, API_SEND_VERIFICATION } from './../../api/endpoints';

const state = () => ({
  profile: null
})

const mutations = {
  SET_PROFILE (state, profile) {
    state.profile = profile;
  },
  DUMMY (state, data) {
    console.log(state);
    console.log(data);
  }
}

const actions = {
  GET_PROFILE ({commit}, userId) {
    console.log(userId)
    return new Promise((resolve, reject) => {
      axios.get(`${API_PROFILES}/${userId}`)
        .then(resp => {
          commit('SET_PROFILE', resp.data[0]);
          resolve(resp.data[0]);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  },
  CREATE_PROFILE ({commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post(API_PROFILES, {userProfile: payload})
        .then(resp => {
          commit('SET_PROFILE', 'resp.data');
          console.log(resp);
          resolve(resp.data);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  },
  SEND_VERIFICATION_EMAIL ({commit}, payload) {
    console.log(payload);
    return new Promise((resolve, reject) => {
      axios.post(API_SEND_VERIFICATION, {newUser: payload})
        .then(resp => {
          resolve(resp);
          commit('DUMMY', 'resp.data');
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  }
}

const books = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default books;