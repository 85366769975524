<template>
  <f7-app id='toad-root' :params="f7params">
    <router-view /> 
  </f7-app>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      f7params: {
        name: 'toad',
        theme: 'auto'
      }
    }
  }
}
</script>

<style lang='scss'></style>
