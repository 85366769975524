import Vue from 'vue';
import { routerWeb } from './routerWeb';
import { routerApp } from './routerApp';
import { store } from './store';

import Antd from 'ant-design-vue';

// import firebase from 'firebase';
/* eslint-disable */
import {fbApp} from './../config';

import { App } from '@capacitor/app';
import { StatusBar } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';

import Framework7 from 'framework7/framework7.esm.bundle.js';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';

import VueApp from './App.vue';

import 'framework7/css/framework7.bundle.css';
import 'ant-design-vue/dist/antd.css';
import './scss/main.scss';

Vue.use(Antd);


const hideStatusBar = async () => {
  await StatusBar.hide();
};

hideStatusBar();

Framework7.use(Framework7Vue);

Vue.config.productionTip = false;

const getDeviceInfoThenInit = async () => {
  const info = await Device.getInfo();
  console.log(info.platform);

  let router = null;
  if (info.platform === 'web') {
    router = routerWeb
  } else if (info.platform === 'ios') {
    router = routerApp
  }

  new Vue({
    router,
    store,
    render: h => h(VueApp),
  }).$mount('#app')

  App.addListener('appUrlOpen', function (data) {
    const slug = data.url.split('.app').pop();
    // We only push to the route if there is a slug present
    if (slug) {
      router.push({
        path: slug,
      });
    }
  });
};

getDeviceInfoThenInit();


