import axios from 'axios';
import { 
  API_OPEN_BOOK, 
  API_CLOSE_BOOK,
  API_COMPLETE_BOOK,
  API_CURRENT_PAGE 
} from './../../api/endpoints';

const state = () => ({
  profileBook: null,
  data: null,
})

const mutations = {
  SET_PROFILE_BOOK (state, data) {
    state.profileBook = data;
  },
  SET_DATA (state, data) {
    state.data = data;
  }
}

const actions = {
  OPEN_BOOK ({commit}, activityPayload) {
    return new Promise((resolve, reject) => {
      axios.patch(`${API_OPEN_BOOK}`, 
        {
          bookId: activityPayload.bookId,
          userId: activityPayload.userId
        }
      )
        .then(resp => {
          console.log(resp.data.profileBook);
          commit('SET_PROFILE_BOOK', resp.data.profileBook);
          resolve(resp.data.profileBook);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  },
  COMPLETE_BOOK ({commit}, activityPayload) {
    return new Promise((resolve, reject) => {
      axios.patch(`${API_COMPLETE_BOOK}`, 
        {
          bookId: activityPayload.bookId,
          userId: activityPayload.userId
        }
      )
        .then(resp => {
          console.log(resp.data.profileBook);
          commit('SET_PROFILE_BOOK', resp.data.profileBook);
          resolve(resp.data.profileBook);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  },
  CLOSE_BOOK ({commit}, activityPayload) {
    return new Promise((resolve, reject) => {
      axios.patch(`${API_CLOSE_BOOK}`, 
        {
          bookId: activityPayload.bookId,
          userId: activityPayload.userId
        }
      )
        .then(resp => {
          console.log(resp.data.profileBook);
          commit('SET_DATA', resp.data);
          resolve(resp.data.profileBook);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  },
  SET_CURRENT_PAGE ({commit}, activityPayload) {
    return new Promise((resolve, reject) => {
      axios.patch(`${API_CURRENT_PAGE}`,
          {
            bookId: activityPayload.bookId,
            userId: activityPayload.userId,
            currentPage: activityPayload.currentPage
          }
        )
        .then(resp => {
          commit('SET_DATA', resp.data);
        })
        .catch(err => {
          console.log(err);
          reject();
        })
    })
  }
}

const books = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default books;