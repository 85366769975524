export const GCS_BUCKET_URL = 'https://storage.googleapis.com/toad-books';
export const BOOK_BUCKET_URL = (bookSlug, fileName) => `https://firebasestorage.googleapis.com/v0/b/toad-web-a2453.appspot.com/o/${bookSlug}%2F${fileName}?alt=media`;
export const API_BASE = 'https://toad-web-a2453.cloudfunctions.net/app/api';
// export const API_BASE = 'http://localhost:5001/toad-web-a2453/us-central1/app/api';

// Books:
export const API_BOOKS = `${API_BASE}/books`;
export const API_BOOK = (slug) => `${API_BASE}/books/${slug}`;

// Users:
export const API_PROFILES = `${API_BASE}/profiles`;

// Activity:
export const API_OPEN_BOOK = `${API_BASE}/activity/open-book`;
export const API_CLOSE_BOOK = `${API_BASE}/activity/close-book`;
export const API_COMPLETE_BOOK = `${API_BASE}/activity/complete-book`;
export const API_CURRENT_PAGE = `${API_BASE}/activity/current-page`;

export const API_AVAILABLE_SIGNING_EVENTS_BY_BOOK = (bookUid) => `${API_BASE}/book-signing-events/${bookUid}/available`;
export const API_SUBMIT_READER_SIGNING_SUBMISSION = (bookUid) => `${API_BASE}/book-signing-event-submission/${bookUid}`;

export const API_SEND_VERIFICATION = `${API_BASE}/send-email-verification`; 

// ADMIN:
export const API_BOOK_SIGNING_EVENTS = `${API_BASE}/book-signing-events`;
export const API_BOOK_SIGNING_EVENTS_BY_BOOK = (bookUid) => `${API_BASE}/book-signing-events/${bookUid}`;
export const API_BOOK_SIGNING_EVENTS_ADD_SIGNATURE = (bookUid) => `${API_BASE}/book-signing-event-submission/${bookUid}/add-signature`;