<template>
  <div class='lp-radio-group'>
    <f7-button 
      v-for='(option, o) in options'
      :key='`lp-radio-option-${o}`'
      class='lp-radio' 
      :class='`${option.disabled ? "disabled" : ""} ${currentSelection && currentSelection.value === option.value ? "selected" : ""}`'
      fill 
      round
      @click='handleRadioSelection(option)'>
      <span class='lp-radio__text'>{{option.text}}</span>
      <span class='lp-radio__meta'>{{option.meta}}</span>
    </f7-button>
  </div>
</template>
<script>
  export default {
    props: {
      options: {
        type: Array,
        required: false,
        default: function () {
          return [
            {
              value: "option-1",
              text: "Option 1",
              meta: "2 slots left",
              disabled: false
            },
            {
              value: "option-2",
              text: "Option 2",
              meta: "3 slots left",
              disabled: false
            }
          ];
        }
      }
    },
    data () {
      return {
        currentSelection: null
      }
    },
    methods: {
      handleRadioSelection (option) {
        this.currentSelection = option;
        this.$emit("radioSelected", option);
      }
    }
  }
</script>
<style lang='scss' scoped>
.lp-radio-group{
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin:2rem 0;
}
.lp-radio{
  padding: 19px 21px !important;
  height: unset !important;
  width: 18rem !important;
  min-width: unset !important;
  max-height: 53px;
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 15px !important;
  display: flex !important;  
  justify-content: space-between;
  background-color: transparent;
  color:$color-text-dark;
  border:1px solid #C3C9DD;
  letter-spacing: 0;
  margin:0.5rem;
  &.active-state{
    background-color: transparent !important;
    color:$color-text-dark !important;
  }
  &__meta{
    font-weight: normal !important;
    font-size:0.8rem;
    color:#636C89;
  }
  &.selected{
    border-color:$color-text-dark;
    background-color: $color-btn-green;
    font-weight: bold !important;
    .lp-radio__meta{
      color: $color-text-dark;
    }
  }
  &.disabled{
    opacity: 0.4 !important;
  }

}
</style>