<template>
  <f7-sheet
    class="demo-sheet-swipe-to-step lp-action-sheet"
    style="height:auto; --f7-sheet-bg-color: #fff; border-radius: 52px 52px 0px 0px;"
    :opened="openState" 
    @sheet:closed="$emit('closed')"
    swipe-to-close
    swipe-to-step
    backdrop
  >
    <div class='lp-action-sheet__header'>
      <p>{{sheetTitle}}</p>
      <lp-button 
        @click="$emit('closed')"
        class='lp-action-sheet__header__closebtn' 
        buttonType='secondary' 
        icon='❌' 
      />
    </div>
    <slot></slot>
  </f7-sheet>
</template>
<script>
import LpButton from './LpButton.vue';
export default {
  components: {
    LpButton
  },
  props: {
    openState: {
      type: Boolean,
      required: false,
      default: false
    },
    sheetTitle: {
      type: String,
      required: false,
      default: 'Sheet Title'
    }
  }
}
</script>
<style lang='scss'>
.sheet-modal {
  background-color: transparent;
  color:#1B2733;
  .sheet-modal-inner{
    background-color: #FFFFFF;
    border-radius: 30px 30px 0px 0px;
    padding-bottom:5rem;
  }
}
.lp-action-sheet{
  h1, h2, h3, h4, h5, h6{
    color:$color-text-dark;
  }
  &__header{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 0px;
    p{
      font-weight: 500;
    }
    &__closebtn{
      position: absolute !important;
      right:1.5rem;
    }
  }
}
</style>