import Vue from 'vue'
import Vuex from 'vuex'

import users from './modules/users';
import books from './modules/books';
import activity from './modules/activity';
import bookSigningEvents from './modules/bookSigningEvents';

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    books,
    users,
    activity,
    bookSigningEvents
  }
})
