<template>
  <f7-page>
    <f7-block>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-button text='Button' buttonType='primary' icon='👋' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='secondary' icon='👋' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='tertiary' icon='👋' />
        </f7-col>
        <f7-col>
          <lp-button text='No thanks' buttonType='clearLight' icon='👋' />
        </f7-col>
      </f7-row>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-button text='Button' buttonType='primary' icon='👋' :disabled='true' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='secondary' icon='👋' :disabled='true' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='tertiary' icon='👋' :disabled='true' />
        </f7-col>
        <f7-col>
          <lp-button text='No thanks' buttonType='clearLight' icon='👋' :disabled='true' />
        </f7-col>
      </f7-row>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-button text='Button' buttonType='primary' icon='👋' :loading='true' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='secondary' icon='👋' :loading='true' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='tertiary' icon='👋' :loading='true' />
        </f7-col>
        <f7-col>
          <lp-button text='No thanks' buttonType='clearLight' icon='👋' :loading='true' />
        </f7-col>
      </f7-row>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-button text='Button' buttonType='primary' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='secondary' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='tertiary' />
        </f7-col>
        <f7-col>
          <lp-button text='No thanks' buttonType='clearLight' />
        </f7-col>
      </f7-row>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-button text='Button' buttonType='primary' :disabled='true' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='secondary' :disabled='true' />
        </f7-col>
        <f7-col>
          <lp-button text='Button' buttonType='tertiary' :disabled='true' />
        </f7-col>
        <f7-col>
          <lp-button text='No thanks' buttonType='clearLight' :disabled='true' />
        </f7-col>
      </f7-row>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-button buttonType='primary' icon='👋' />
        </f7-col>
        <f7-col>
            <lp-button buttonType='secondary' icon='👋' />
        </f7-col>
        <f7-col>
          <lp-button buttonType='tertiary' icon='👋' />
        </f7-col>
        <f7-col>
          <lp-button buttonType='clearLight' icon='👋' />
        </f7-col>
      </f7-row>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-button buttonType='primary' icon='👋' :disabled='true' />
        </f7-col>
        <f7-col>
            <lp-button buttonType='secondary' icon='👋' :disabled='true' />
        </f7-col>
        <f7-col>
          <lp-button buttonType='tertiary' icon='👋' :disabled='true' />
        </f7-col>
        <f7-col>
          <lp-button buttonType='clearLight' icon='👋' :disabled='true' />
        </f7-col>
      </f7-row>
      <f7-row class='lp-row'></f7-row>
      <f7-row class='lp-row'></f7-row>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-avatar />
        </f7-col>
        <f7-col>
            <lp-avatar 
              pictureUrl='https://toadhouse.s3.amazonaws.com/avatar-picture.png' 
              :notifications='true'
            />
        </f7-col>
        <f7-col></f7-col>
      </f7-row>
      <f7-row class='lp-row'>
        <f7-col>
          <lp-menu-chip />
        </f7-col>
        <f7-col>
            <lp-menu-chip 
              pictureUrl='https://toadhouse.s3.amazonaws.com/avatar-picture.png' 
              :notifications='true'
            />
        </f7-col>
        <f7-col></f7-col>
      </f7-row>
    </f7-block>

    <div class='lp-block-wrap-light'>
      <f7-block>
        <f7-row class='lp-row'>
          <f7-col>
            <lp-input 
              :inputValue='inputValue'
              @input='handleInputChange'
              helperText='Helper text goes here' />
          </f7-col>
        </f7-row>
        <f7-row class='lp-row'>
          <f7-col>
            <lp-input 
              :inputValue='inputValue'
              @input='handleInputChange'
              startingIcon='🔍' />
          </f7-col>
        </f7-row>
        <f7-row class='lp-row'>
          <f7-col>
            <lp-input 
              :inputValue='inputValue'
              @input='handleInputChange'
              endingIcon='👨‍👩‍👦' />
          </f7-col>
        </f7-row>
        <f7-row class='lp-row'>
          <f7-col>
            <lp-input 
              :inputValue='inputValue'
              @input='handleInputChange'
              endingIcon='👨‍👩‍👦' 
              :error='{message: "Problem with your email address"}' />
          </f7-col>
        </f7-row>
      </f7-block>  
    </div>
    <div class='lp-block-wrap-light'>
      <f7-block>
        <p style="color:#000000;" v-if='currentRadio'>current radio selection: {{currentRadio.text}}</p>
        <f7-row class='lp-row'>
            <lp-radio-group :options='
              [
                {
                  value: "option-1",
                  text: "Option 1",
                  meta: "2 slots left",
                  disabled: false
                },
                {
                  value: "option-2",
                  text: "Option 2",
                  meta: "3 slots left",
                  disabled: false
                },
                {
                  value: "option-3",
                  text: "Option 3",
                  meta: "2 slots left",
                  disabled: false
                },
                {
                  value: "option-4",
                  text: "Option 4",
                  meta: "3 slots left",
                  disabled: false
                }
              ]
            ' 
            @radioSelected='handleRadioChange'/>
        </f7-row>
      </f7-block>
    </div>
    
  </f7-page>
</template>
<script>
import LpButton from './../components/lilypad/LpButton.vue';
import LpInput from './../components/lilypad/LpInput.vue';
import LpRadioGroup from './../components/lilypad/LpRadioGroup.vue';
import LpMenuChip from './../components/lilypad/LpMenuChip';
import LpAvatar from './../components/lilypad/LpAvatar';
export default {
  components: {
    LpButton, LpInput, LpRadioGroup, LpMenuChip, LpAvatar
  },
  data () {
    return {
      inputValue: null,
      currentRadio: null
    }
  },
  methods: {
    handleInputChange (inputValue) {
      this.inputValue = inputValue;
    },
    handleRadioChange (value) {
      this.currentRadio = value;
    }
  }
}
</script>
<style lang='scss'>
.lp-row{
  margin-bottom: 16px;
}
.lp-block-wrap-light{
  padding: 40px;
  background-color: white;
}
</style>