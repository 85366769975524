<template>
  <div class='toad-book'>
    <transition name="loader">
      <div class='book-loading-wrap' v-show='loaderState'>
        <BookLoadingAnimation />
      </div>
    </transition>
    <transition name="loader">
      <div v-show='!loadingBook'>
        <book-nav-btn class='book-nav-btn' @navButtonClick='showBookNav = !showBookNav' />

        <transition name="fade" mode='in-out'>
          <book-nav 
            v-show='showBookNav' 
            class='book-nav' 
            :book='book' 
            :currentPage='currentPage' 
            @bookDetailBtnClick='actionSheet={
              show: true,
              type: "book-detail",
              title: "📙 About the book"
            }'
            @closeBookClick='handleCloseBook' 
            @spreadClick='handleNavSpreadClick'/>
        </transition>

        <book-body 
          class='book-body' 
          :book='book' 
          :currentPage='currentPage' />


        <transition name="loader">  
          <book-modal v-if='showSigningModal'>

            <template v-slot:body>
              <h2>Get your book signed by 
                <span 
                  v-for='(creator, c) in book.creators' 
                  :key='`book-creator-${c}`'>
                  {{creator.firstName}} {{creator.lastName}}</span>.
              </h2>
              <lp-button 
                text='Submit Book' 
                buttonType='secondary' 
                icon='✍️' 
                @click='actionSheet={
                  show: true,
                  type: "book-signing",
                  title: "✍️ Get your book signed"
                }'
                />
            </template>

            <template v-slot:footer>
              <lp-button 
                text='No thanks' 
                buttonType='clearLight' 
                @click='showSigningModal=false'
                />
            </template>

          </book-modal>
        </transition>

        <transition name="loader"> 
          <book-modal v-if='showEndingModal'>

            <template v-slot:body>
              <h2>Are you done reading {{book.title}}?
              </h2>
              <lp-button 
                text="Yes, I'm Done."
                buttonType='secondary' 
                icon='🏁' 
                @click='handleFinishBook'
                />
            </template>

            <template v-slot:footer>
              <lp-button 
                text='Not yet.' 
                buttonType='clearLight' 
                @click='showEndingModal=false'
                />
            </template>

          </book-modal>
        </transition>


        <lp-action-sheet 
          @closed='actionSheet.show=false' 
          :openState='actionSheet.show'
          :sheetTitle='actionSheet.title'>

          <div v-if='actionSheet.type === "book-detail"'>
            <div class="sheet-modal-swipe-step">
              <div class="padding" v-if='book'>
                <h3 class='text-align-center'>{{book.title}}</h3>
                <p>{{book.byline}}</p>
                <p>{{book.shortSummary}}</p>
                <p>hi there how are you?</p>
              </div>
              <div class="padding-horizontal padding-bottom">
                <div class="margin-top text-align-center">Swipe up for more details</div>
              </div>
            </div>
            <f7-block-title medium class="margin-top">
              <div v-if='book' v-html='book.summary'></div>
            </f7-block-title>
          </div>

          <div v-if='actionSheet.type === "book-signing"'>
            
            <div class='book-form signing names' v-show='signingFormStep === 1'>
              <h2>Want a personalized signing?</h2> 
              <p>Who shall it be signed to?</p>
              <div class='input-columns'>
                <f7-list class='col1'>
                  <lp-input 
                    :inputValue='signingData.signingTo[0].name'
                    @input='(inputValue) => { signingData.signingTo[0].name = inputValue }'
                    inputPlaceholder='Name (optional)'/>
                  <lp-input 
                    :inputValue='signingData.signingTo[1].name'
                    @input='(inputValue) => { signingData.signingTo[1].name = inputValue }'
                    inputPlaceholder='Name (optional)'/>
                  <lp-input 
                    :inputValue='signingData.signingTo[2].name'
                    @input='(inputValue) => { signingData.signingTo[2].name = inputValue }'
                    inputPlaceholder='Name (optional)'/>
                </f7-list>
                <f7-list class='col2'>
                  <lp-input 
                    :inputValue='signingData.signingTo[3].name'
                    @input='(inputValue) => { signingData.signingTo[3].name = inputValue }'
                    inputPlaceholder='Name (optional)'/>
                  <lp-input 
                    :inputValue='signingData.signingTo[4].name'
                    @input='(inputValue) => { signingData.signingTo[4].name = inputValue }'
                    inputPlaceholder='Name (optional)'/>
                  <lp-input 
                    :inputValue='signingData.signingTo[5].name'
                    @input='(inputValue) => { signingData.signingTo[5].name = inputValue }'
                    inputPlaceholder='Name (optional)'/>
                </f7-list>
               </div> 
              <lp-button text='Next' buttonType='primary' @click='signingFormGoTo(2)' :disabled='signingNamesButtonDisabled' />
            </div>

            <div class='book-form signing dates' v-show='signingFormStep === 2'>
                <h2>Select a signing date.</h2> 
                <p>
                  <span 
                    v-for='(creator, c) in book.creators' 
                    :key='`book-creator-${c}`'>
                    {{creator.firstName}} {{creator.lastName}}
                  </span> will sign your book the day you choose.
                </p>
                <lp-radio-group :options='this.availableSigningsForm' @radioSelected='handleSigningsRadioChange'/>
                <lp-button text='Back' buttonType='clearDark' @click='signingFormGoTo(1)' />
                <lp-button 
                  text='Next' 
                  buttonType='primary' 
                  :disabled='signingDateButtonDisabled' 
                  :loading='signingFormLoading'
                  @click='signingFormGoTo(3)' />
            </div>
            <div class='book-form signing review' v-show='signingFormStep === 3'>
              <h2>Take a moment to review your submission.</h2> 
              <div class='review-stack'>
                <div class='review-item'>
                  <div class='item-details'>
                    <p>Your book will be signed to:</p>
                    <h2 v-if='signingSubmissionNames.length > 0'>
                      <span v-for='(item, i) in signingSubmissionNames' :key='`signing-to-names-${i}`'>{{nameWithComma(i)}}</span> 
                    </h2>
                    <h2 v-else>--</h2>
                  </div>
                  <lp-button text='Edit' buttonType='clearDark' @click='signingFormGoTo(1)' icon='✏️' />
                </div>
                <div class='review-item'>
                  <div class='item-details'>
                    <p>Your book will be signed on:</p>
                    <h2 v-if='signingData.signingDate.readable'>{{signingData.signingDate.readable}}</h2>
                    <h2 v-else>--</h2>
                  </div>
                  <lp-button text='Edit' buttonType='clearDark' @click='signingFormGoTo(2)' icon='✏️' />
                </div>
              </div>
              <lp-button 
                text='Submit' 
                buttonType='primary' 
                :loading='signingFormLoading'
                @click='signingFormGoTo(4)' />
            </div>
            <div class='book-form signing confirmation' v-show='signingFormStep === 4'>
              <h2>Great! You're book is registered.</h2> 
              <p>Your very own signing will be revealed in this book on {{signingData.signingDate.readable}}</p>
              <lp-button 
                text='Continue reading' 
                buttonType='primary' 
                @click='() => {
                  actionSheet= {
                    show: false,
                    type: null,
                    title: null
                  };
                  showSigningModal=false;
                }' />
            </div>
          </div>
          
        </lp-action-sheet>
      </div>
    </transition>
  </div>
</template>
<script>
import firebase from "firebase";
import { mapActions, mapState, mapMutations } from 'vuex';
import BookNavBtn from './../components/BookNavBtn.vue';
import BookNav from './../components/BookNav.vue';
import BookBody from './../components/BookBody.vue';
import BookModal from './../components/BookModal.vue';
import LpActionSheet from './../components/lilypad/LpActionSheet.vue';
import LpButton from './../components/lilypad/LpButton.vue';
import LpInput from './../components/lilypad/LpInput.vue';
import LpRadioGroup from './../components/lilypad/LpRadioGroup.vue';
import BookLoadingAnimation from './../components/BookLoadingAnimation.vue';
import moment from 'moment';
export default {
  components: {
    BookNavBtn, BookNav, BookBody, BookModal, LpActionSheet, LpButton, LpInput, LpRadioGroup, BookLoadingAnimation
  },
  data () {
    return {
      currentPage: 1,
      user: null,
      bookProfile: null,
      loadingBook: true,
      loaderState: true,
      actionSheet: {
        show: false,
        type: null,
        title: null
      },
      showBookNav: false,
      availableSigningsForm: null,
      showSigningModal: false,
      showEndingModal: false,
      signingFormStep: 1,
      signingFormLoading: false,
      signingData: {
        signingDate: {
          readable: null,
          eventId: null
        },
        signingTo: [
          { name: null },
          { name: null },
          { name: null },
          { name: null },
          { name: null },
          { name: null }
        ]
      }
    }
  },
  computed: {
    ...mapState('books', ['book']),
    signingSubmissionNames () {
      let signingToNames = this.signingData.signingTo;
      signingToNames = this.signingData.signingTo.filter(item => item.name);
      
      return signingToNames;
    },
    signingDateButtonDisabled () {
      let disabled = true;
      if (this.signingData.signingDate.eventId) {
        disabled = false;
      } else {
        disabled = true;
      }
      return disabled;
    },
    signingNamesButtonDisabled () {
      let disabled = true;
      this.signingData.signingTo.forEach(toItem => {
        if (toItem.name) {
          disabled = false;
        }
      })
      return disabled;
    }
  },
  watch: {
    $route: function (value) {
      this.goToPage(value.query.page);
    }
  },
  mounted () {
    screen.orientation.lock('landscape');    
    firebase.auth().onAuthStateChanged((user) => {
      console.log('auth state check');
      console.log(user);
      if (user) {
        this.user = user;
        // User is signed in.
        if (!this.book || (this.book.slug !== this.$route.params.slug)) {
          this.GET_BOOK(this.$route.params.bookSlug)
          .then((bookResponse) => {
              
            this.OPEN_BOOK({userId: user.uid, bookId: this.book.uid})
              .then(openProfileBook => {
                console.log('openProfileBook: ');
                console.log(openProfileBook);
                this.bookProfile = openProfileBook;

                if (!this.bookProfile.signing.BookSigningEventUid) {
                  this.GET_AVAILABLE_SIGNING_DATES(bookResponse.uid)
                    .then(availableSigningsResponse => {
                      console.log(availableSigningsResponse);
                      this.createSigningsForm(availableSigningsResponse);                      
                      // if there is already a page query param in URL, flip to there:
                      if (this.$route.query.page) {
                        this.goToPage(this.$route.query.page);
                      } else {
                        // otherwise, go to the stored current page from user profile:
                        this.$router.push({ query: { page: openProfileBook.currentPage } });
                      }
                      console.log('completed loading book:');
                      this.completeLoadingBook();

                    })
                    .catch(err => { console.log(err) });
                } else {
                  // if book has been signed set the signed colophon spread:
                  if (this.bookProfile.signing.signingImage) {
                    this.SET_BOOK_SIGNING(this.bookProfile.signing.signingImage);  
                  }
                  if (this.$route.query.page) {
                    this.goToPage(this.$route.query.page);
                  } else {
                    // otherwise, go to the stored current page from user profile:
                    this.$router.push({ query: { page: openProfileBook.currentPage } });
                  }
                  this.completeLoadingBook();
                }
                
              })
              .catch(err => {
                console.log(err);
                console.log('there was a problem.');
              });

          }).catch(() => {
            console.log('there was a problem.');
          });
        }
      } else {
        // No user is signed in.
        this.$router.push({name: 'login'});
      }
    });

  },
  methods: {
    ...mapActions('books', ['GET_BOOK', 'GET_AVAILABLE_SIGNING_DATES']),
    ...mapActions('activity', ['OPEN_BOOK', 'CLOSE_BOOK', 'COMPLETE_BOOK', 'SET_CURRENT_PAGE']),
    ...mapActions('bookSigningEvents', ['SUBMIT_SIGNING']),
    ...mapMutations('books', ['SET_BOOK_SIGNING']),
    completeLoadingBook () {
      this.loadingBook = false;
      setTimeout(() => {
        this.loaderState = false;  
      }, 5000)
    },
    goToPage (pageNumber) {
      const lastSpreadIndex = this.book.body.length - 1;
      const lastPageNumber = this.book.body[lastSpreadIndex].pages.right.pageNumber;

      if (pageNumber > lastPageNumber) {
        
        this.$router.push({ 
          query: { 
            page: lastPageNumber
          } 
        });
        this.showEndingModal = true; 

      } else {
        
        let pageNum = Number(pageNumber);
        let isValid = this.validatePage(pageNum);
        console.log(pageNum);
        if (isValid) {
          this.currentPage = pageNum;
          this.SET_CURRENT_PAGE({
            userId: this.user.uid, 
            bookId: this.book.uid,
            currentPage: this.currentPage
          })
          this.pageBasedEventCheck(pageNum);
        } else {
          this.$router.push({ 
            query: { 
              page: 1
            } 
          });
        }

      }

    },
    validatePage (page) {
      if (page) {
        let pageNumber = Number(page);
        const lastSpreadIndex = this.book.body.length - 1;
        const lastPageNumber = this.book.body[lastSpreadIndex].pages.right.pageNumber;
        console.log(lastPageNumber)
        if ((pageNumber <= (lastPageNumber)) && (pageNumber > 0)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    handleNavSpreadClick (spread) {
      this.showBookNav = false;
      this.$router.push({ 
        query: { 
          page: spread.pages.left.pageNumber
        } 
      });
    },
    handleCloseBook () {
      this.showBookNav = false;
      setTimeout(() => {
        this.$router.push('/home');
      }, 300);
      this.CLOSE_BOOK({userId: this.user.uid, bookId: this.book.uid});
    },
    handleFinishBook () {
      console.log('finish book');
      this.COMPLETE_BOOK({userId: this.user.uid, bookId: this.book.uid});
      this.$router.push('/home');
    },
    pageBasedEventCheck(pageNum) {
      console.log(this.availableSigningsForm);
      // const lastSpreadIndex = this.book.body.length - 1;
      // const lastPageNumber = this.book.body[lastSpreadIndex].pages.right.pageNumber;

      if (pageNum === 3 || pageNum === 4) {
        if (this.availableSigningsForm) {
          this.showSigningModal = true;  
        }
      }
      // if (pageNum > lastPageNumber) {  
      //   this.showEndingModal = true; 
      // }        

      // if page is this
      // --> check for this
      // so:
      // if page == 1;
      // if book has available signing slots
      // if user has not signed up for a slot:
      // reveal signing scheduler modal
      
      // if page == last page;
      // reveal modal that shows "finish book" button
    },
    createSigningsForm (signingsData) {
      const availability = signingsData.filter(item => item.availableSlots > 0);
      if (availability.length > 0) {
        this.availableSigningsForm = signingsData.map((signing) => {
          const disabled = Number(signing.availableSlots) === 0;
          return {
            value: signing.BookSigningEventUid,
            text: moment(signing.date).format('MMMM Do, YYYY'),
            meta: `${signing.availableSlots} spots left`,
            disabled
          }
        })
      }
    },
    handleSigningsRadioChange (selectedDate) {
      // console.log(selectedDate);
      // console.log(moment(selectedDate).format('MMMM Do, YYYY'))
      if (selectedDate) {
        this.signingData.signingDate = {
          readable: selectedDate.text,
          eventId: selectedDate.value
        };
      }
    },
    signingFormGoTo(step) {
      if (step === 4) {        
        this.submitSigningForm();
      } else {
        this.signingFormStep = step;
      }
    },
    nameWithComma (index) {
     if (index !== this.signingSubmissionNames.length-1){
       return `${this.signingSubmissionNames[index].name}, `;
     } else {
       return this.signingSubmissionNames[index].name;
     }
    },
    submitSigningForm () {
      // console.log(this.signingData);
      this.signingFormLoading = true;
      const signingToList = this.signingData.signingTo.filter(item => item.name);
      const signingEventId = this.signingData.signingDate.eventId;
      const signingSubmission = {
        userId: this.user.uid,
        signingTo: signingToList,
        BookSigningEventUid: signingEventId
      }
      const payload = {
        bookUid: this.book.uid,
        submission: signingSubmission,
      };
      console.log(signingSubmission);
      this.SUBMIT_SIGNING(payload)
        .then(resp => {
          console.log(resp);
          this.signingFormLoading = false;
          this.signingFormStep = 4;
          this.availableSigningsForm = null;
          
          this.$router.push({ 
            query: { 
              page: this.currentPage++
            } 
          });
        })
        .catch(err => {
          console.log(err);
          this.signingFormLoading = false;
          this.signingFormStep = 3;
        })
    }
  }
}
</script>
<style lang='scss'>
.toad-book {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .book-loading-wrap{
    position: absolute;
    top:0;
    left: 0;
    width:100%;
    height:100%;
    background-color: #1B2733;
    z-index: 99999999;
  }
  .book-nav-btn{
    position: fixed;
    top: 0.5rem;
    left: 1rem;
    z-index: 3;
  }
  .book-nav{
    position: fixed;
    left:0;
    top:0;
    z-index: 2;
  }
  .book-body{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 1;
  }
}
.book-form{
  &.signing{
    text-align: center;
    .input-columns{
      display: flex;
      justify-content: center;
    }
    .review-stack{
      position: relative;
      margin:0 auto;
      width:50%;
      border:1px solid #C3C9DD;
      border-radius: 8px;
      text-align: left;
      margin-top:4rem;
      margin-bottom: 3rem;
      .review-item{
        padding:0.5rem 1.65rem;
        display: flex;
        justify-content: space-between;
        border-bottom:1px solid #C3C9DD;
        align-items: center;
        .item-details{
          p{
            font-weight: normal !important;
            font-size:0.9rem;
            color:#636C89;
            margin-bottom:0;
          }
          h2{
            margin-top: 0.4rem;
          }
        }
        &:last-of-type{
          border-bottom:0px solid #C3C9DD;
        }
      }
    }

  }
}
.loader-enter-active, .loader-leave-active {
  transition: all .5s;
  // transform: translateY(0px) scale(1);
  opacity: 1;
}
.loader-enter, .loader-leave-to{
  opacity: 0;
  // transform: translateY(10px) scale(0.9);
}
.fade-enter-active, .fade-leave-active {
  transition: all .5s;
  transform: translateY(0px) scale(1);
  opacity: 1;
}
.fade-enter, .fade-leave-to{
  opacity: 0;
  transform: translateY(10px) scale(0.9);
}
</style>