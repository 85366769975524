<template>
  <f7-list-input
    class='lp-input'
    :class='[
      {"has-ending-icon": endingIcon}, 
      {"has-starting-icon": startingIcon},
      {"has-error": error}
    ]'
    :value='inputValue'
    outline
    :label="inputPlaceholder"
    floating-label
    :info='dynamicHelperText'
    :type="inputType"
    :placeholder="inputPlaceholder"
    :inputStyle='inputStyle'
    @input='(e) => $emit("input", e.target.value)'
  >
  <span 
    v-if='startingIcon' 
    class='lp-input__icon lp-input__starting-icon input-icon' 
    slot='inner-end'>{{startingIcon}}</span>
  <span 
    v-if='endingIcon' 
    class='lp-input__icon lp-input__ending-icon' 
    slot='inner-end'>{{dynamicEndingIcon}}</span>
</f7-list-input>
</template>
<script>
export default {
  props: {
    inputValue: {
      type: String,
      required: false,
      default: null
    },
    startingIcon: {
      type: String,
      required: false,
      default: null
    },
    endingIcon: {
      type: String,
      required: false,
      default: null
    },
    error: {
      type: Object,
      required: false,
      default: null
    },
    helperText: {
      type: String,
      required: false,
      default: null
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: 'Placeholder...'
    },
    inputType: {
      type: String,
      required: false,
      default: 'text'
    }
  },
  data () {
    return {
      inputStyle: {
        backgroundColor:'#F8F8F8',
        height: '45px'
      }
    }
  },
  computed: {
    dynamicHelperText () {
      let messageText = null;
      if (this.error) {
        messageText = this.error.message;
      } else if (this.helperText) {
        messageText = this.helperText;
      } else {
        messageText = null;
      }
      return messageText;
    },
    dynamicEndingIcon () {
      let icon = null;
      if (this.error) {
        icon = '🛑';
      } else if (this.endingIcon) {
        icon = this.endingIcon;
      } else {
        icon = null;
      }
      return icon;
    }
  },
  methods: {
    // handleOnInput (e) {
    //   // this.$emit('input', e.target.value);
    // }
  }
}
</script>
<style lang='scss'>
.lp-input{
  max-width: 300px;
  list-style-type: none;
  &__icon{
    font-size: 1.35rem;
    position: absolute;
    top: 22px;
  }
  &__ending-icon{
    right: 26px;
  }
  &__starting-icon{
    left: 10px;
  }
  .item-input-focused{
    .item-floating-label{
      background-color: red;
    }
  }
}
</style>