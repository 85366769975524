<template>
  <f7-page id='toad-signup'>
    <f7-block>
      <f7-row>
        <f7-col>
          <f7-list class='toad-form'>
            <lp-input 
              :inputValue='firstName'
              @input='(inputValue) => handleInputChange("firstName", inputValue)'
              inputPlaceholder='First Name'
              helperText='Start with your first name.' />
            <lp-input 
              :inputValue='lastName'
              @input='(inputValue) => handleInputChange("lastName", inputValue)'
              inputPlaceholder='Last Name'
              helperText='Now your last.' />
            <lp-input 
              :inputValue='email'
              @input='(inputValue) => handleInputChange("email", inputValue)'
              inputPlaceholder='Email'
              inputType='email'
              helperText='Signup using your email address' />
            <lp-input 
              :inputValue='password'
              @input='(inputValue) => handleInputChange("password", inputValue)'
              inputPlaceholder='Password'
              inputType='password'
              helperText='Choose a password' />
          </f7-list>
        </f7-col>
      </f7-row>
      <f7-row>
        <lp-button 
          @click='handleSignup' 
          text='Signup'
          buttonType='primary'
        />
        <p>or <span @click='$router.push("/login")'>login</span></p>
      </f7-row>    
    </f7-block>
  </f7-page>
</template>
<script>
import firebase from "firebase";
import {mapActions} from 'vuex';
import LpButton from './../components/lilypad/LpButton.vue';
import LpInput from './../components/lilypad/LpInput.vue';
export default {
  components: {
    LpInput, LpButton
  },
  data () {
    return {
      firstName: null,
      lastName: null,
      email: null,
      password:null
    }
  },
  mounted () {
    screen.orientation.lock('portrait');
  },
  methods: {
    ...mapActions('users', ['CREATE_PROFILE', 'SEND_VERIFICATION_EMAIL']),
    handleInputChange(inputType, inputValue) {
      this[inputType] = inputValue;
      console.log(this[inputType]);
    },
    handleSignup() {
      // var actionCodeSettings = {
      //   // URL you want to redirect back to. The domain (www.example.com) for this
      //   // URL must be in the authorized domains list in the Firebase Console.
      //   url: 'https://hellotoad.com/verify-email',
      //   // This must be true.
      //   handleCodeInApp: false
      //   // iOS: {
      //   //   bundleId: 'com.toadhouse.app'
      //   // }
      //   // android: {
      //   //   packageName: 'com.example.android',
      //   //   installApp: true,
      //   //   minimumVersion: '12'
      //   // },
      //   // dynamicLinkDomain: 'example.page.link'
      // };
      firebase
      .auth()
      .createUserWithEmailAndPassword(this.email, this.password)
      .then((res) => {
        res.user
          .updateProfile({
            displayName: this.firstName
          })
          .then(() => {
            console.log(res.user.uid);
            const profile = {
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.email,
              userId: res.user.uid
            };
            this.CREATE_PROFILE(profile)
              .then((resp) => {
                console.log(resp);
                console.log('create profile!');
                firebase.auth().signOut().then(() => {
                  firebase.auth().onAuthStateChanged(() => {
                    this.SEND_VERIFICATION_EMAIL({
                      firstName: this.firstName,
                      lastName: this.lastName,
                      email: this.email
                    })
                      .then(resp => {
                        console.log(resp);
                        this.$router.push({name: 'verification-sent'});
                      })
                      .catch(err => {
                        console.log(err);
                      })
                  })
                });

              })
              .catch((error) => {
                // var errorCode = error.code;
                // var errorMessage = error.message;
                console.log(error);
                console.log('ERRRRRR! 1');
              });

                // firebase.auth().sendSignInLinkToEmail(this.email, actionCodeSettings)
                //   .then(() => {
                //     // The link was successfully sent. Inform the user.
                //     // Save the email locally so you don't need to ask the user for it again
                //     // if they open the link on the same device.                    
                //     // after account and profile has been created
                //     // redirect to email verification page
                //     console.log('send signin link to email!');
                //     firebase.auth().signOut().then(() => {
                //       firebase.auth().onAuthStateChanged(() => {
                //         console.log('route to verify email!');
                //         this.$router.push({name: 'verify-email'});
                //       })
                //     });

                //   })
                //   .catch((error) => {
                //     // var errorCode = error.code;
                //     // var errorMessage = error.message;
                //     console.log(error);
                //     console.log('ERRRRRR! 1');
                //   });

          })
          .catch(err => {
            console.log(err);
            console.log('ERRRRRR! 2');
          });
      })
      .catch((error) => {
         alert(error.message);
         console.log('ERRRRRR! 3');
      });
    }
  }
}
</script>
<style lang='scss'>
  .toad-form{
    background-color: white;
  }
</style>