<template>
  <div id="toad-admin">
    <h2>All books:</h2>
    <div class='books'>
      <div class='books__item' v-for='(book, b) in allBooks' :key='`${book.slug}-${b}`' @click='$router.push(`admin/${book.slug}`)'>
        <div class='books__item__cover'>
          <img :src="BOOK_BUCKET_URL(book.slug, book.assets.cover.img)" />  
        </div>
        <div class='books__item__details'>
          <p>{{book.title}}</p>
          <p>slug: {{book.slug}}</p>
        </div>
      </div>
    </div>
    <h2>Add a new book:</h2>
    <div class='add-book'>
      <a-textarea 
        class='add-book__textarea'
        placeholder="Add book json.." 
        v-model='bookData' 
        allow-clear 
      />
      <a-button 
        class='add-book__savebtn'
        type="primary" 
        icon="cloud-upload" 
        @click='saveBook' 
        :loading='loading'>
        Save Book
      </a-button>
    </div>
  </div>
</template>

<script>
import { BOOK_BUCKET_URL, API_BOOKS } from './../api/endpoints';
import axios from 'axios';
export default {
  name: 'App',
  data () {
    return {
      loading: false,
      bookData: null,
      booksApi: API_BOOKS,
      BOOK_BUCKET_URL,
      allBooks: null
    }
  },
  created () {
    this.getAllBooks();
  },
  methods: {
    getAllBooks () {
      axios.get(this.booksApi)
        .then(resp => {
          this.allBooks = resp.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    saveBook () {
      this.loading = true;
      console.log(this.bookData);
      const bookdataObject = JSON.parse(this.bookData);
      axios.post(this.booksApi, {book: bookdataObject})
        .then(() => {
          this.getAllBooks();
          this.loading = false;
          this.bookData = null;
        })
        .catch(err => {
          console.log(err);
          this.loading = false;
        })
    }
  }
}
</script>

<style lang='scss' scoped>
#toad-admin{
  padding: 4rem;
  .add-book{
    &__textarea{
      textarea{
        min-height: 20rem;
      }
    }
    &__savebtn{
      margin:1rem 0rem;
      width:100%;
    }
  }
  .books{
    display: flex;
    &__item{
      margin-right:1rem;
      width: 33.333333%;
      border: 1px solid #e6e6e6;
      border-radius: 3px;
      box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.06);
      &:nth-of-type(3n) {
        margin-right:0rem;
      }
      &__cover{
        img{
          width:100%;
        }
      }
      &__details{
        padding: 2rem;
      }
    }
  }
}
</style>
