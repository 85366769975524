<template>
  <p>you've been verified!</p>
</template>
<script>
import firebase from "firebase";
export default {
  created () {
    const url_string = window.location.href
    const url = new URL(url_string);
    const mode = url.searchParams.get("mode");
    const actionCode = url.searchParams.get("oobCode");
    const continueUrl = url.searchParams.get("continueUrl");
    const lang = url.searchParams.get("lang");
    console.log(mode);
    console.log(actionCode);
    console.log(continueUrl);
    console.log(lang);

    // handleVerifyEmail(actionCode, continueUrl, lang) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      firebase.auth.applyActionCode(actionCode).then((resp) => {
        console.log(resp)
        console.log('VERIFIED!');
        // Email address has been verified.

        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
      }).catch((error) => {
        console.log(error)
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
      });
    // }

  } 
}
</script>
<style lang='scss'></style>