<template>
  <nav class='toad-main-nav'>
    <img class='toad-main-nav__logo' src="./../assets/toadhouse-symbol.svg">
    <p class='toad-main-nav__welcome'>👋 Welcome back, {{displayName}}!</p>
    <lp-menu-chip @click='$emit("profile-click")' />
  </nav>
</template>
<script>
import LpMenuChip from './lilypad/LpMenuChip.vue';
  export default {
    components: {
      LpMenuChip
    },
    props: {
      displayName: {
        type: String,
        required: true
      }
    }
  }
</script>
<style lang='scss'>
.toad-main-nav{
  position: relative;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include respond-to('small') {
    padding: 5.5rem 1.5rem 2rem;
  }
  &__logo{
    @include respond-to('small') {
      width: 1.5rem;
    }
  }
  &__welcome{
    margin:0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-weight: 500;
    @include respond-to('small'){
      display: none;
    }
  }
  .chip{
    color:$color-body-text;
    flex-direction: row-reverse;
    padding: 5px;
    padding-left: 14px;
    font-size: 1rem;
    height: auto;
    background-color: #334150;
    border-radius: 200px;

    .chip-media{
      margin-left: unset;
      height: 34px;
      width: 34px;
    }
    .chip-label{
      margin-right: 9px;
    }
  }
}

</style>