import axios from 'axios';
import _ from 'lodash';
// import { BOOK_DATA } from '../../api/data2';
import { 
  API_BOOKS, 
  API_BOOK,
  API_AVAILABLE_SIGNING_EVENTS_BY_BOOK
} from './../../api/endpoints';

const state = () => ({
  book: null,
  allBooks: null,
  availableBookSignings: null
})

const mutations = {
  SET_BOOK (state, book) {
    state.book = book;
  },
  SET_BOOK_SIGNING (state, signingImage) {
    const bookClone = _.cloneDeep(state.book);
    bookClone.body[1].image = signingImage;
    bookClone.body[1].pages.left['colophon'] = true;
    bookClone.body[1].pages.right['colophon'] = true;
    state.book = bookClone;
    console.log(state.book);
  },
  SET_AVAILABLE_SIGNINGS (state, signingsList) {
    state.availableBookSignings = signingsList;
  },
  SET_ALL_BOOKS (state, allBooks) {
    state.allBooks = allBooks
  }
}

const actions = {
  GET_BOOK ({commit}, slug) {
    return new Promise((resolve, reject) => {
      axios.get(API_BOOK(slug))
        .then(resp => {
          commit('SET_BOOK', resp.data[0]);
          console.log(resp);
          resolve(resp.data[0]);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  },
  GET_ALL_BOOKS ({commit}) {
    return new Promise((resolve, reject) => {
      axios.get(API_BOOKS)
        .then(resp => {
          commit('SET_ALL_BOOKS', resp.data);
          console.log(resp);
          resolve(resp.data);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  },
  GET_AVAILABLE_SIGNING_DATES ({commit}, bookUid) {
    return new Promise((resolve, reject) => {
      console.log(bookUid);
      axios.get(API_AVAILABLE_SIGNING_EVENTS_BY_BOOK(bookUid))
        .then(resp => {
          console.log(resp);
          commit('SET_AVAILABLE_SIGNINGS', resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    })
  }
}

const books = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default books;